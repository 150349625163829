<template>
    <v-navigation-drawer
        :value="isFiltersActive"
        temporary
        touchless
        :right="!$vuetify.rtl"
        :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
        app
        @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
        <v-card height="100%">
            <div class="drawer-header d-flex align-center mb-4">
                <span class="font-weight-semibold text-base text--primary">{{ $t('Filters') }}</span>
                <v-spacer></v-spacer>
                <v-btn color="primary" icon small @click="$emit('update:is-add-new-user-sidebar-active', false)">
                    <v-icon size="22">
                        {{ mdiClose }}
                    </v-icon>
                </v-btn>
            </div>

            <v-card-text>
                <v-btn @click="deleteFilters" expanded class="d-flex ml-auto text-capitalize" text color="error">
                    {{ $t('DeleteFilters') }}
                    <v-icon right>
                        {{ mdiCloseBoxOutline }}
                    </v-icon>
                </v-btn>
                <div class="mt-4">
                    <v-autocomplete
                        clearable
                        v-model="query.filters.category.$containsi"
                        item-value="id"
                        item-text="name"
                        :items="categories"
                        filled
                        shaped
                        :label="$t('Category')"></v-autocomplete>
                    <div class="mt-6">
                        <v-btn @click="applyFilters" color="primary" class="me-3" type="submit">
                            {{ $t('Buttons.Filter') }}
                        </v-btn>
                        <v-btn
                            @click="$emit('update:is-add-new-user-sidebar-active', false)"
                            color="secondary"
                            outlined>
                            {{ $t('Buttons.Cancel') }}
                        </v-btn>
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-navigation-drawer>
</template>

<script>
    import { mdiClose, mdiCloseBoxOutline } from '@mdi/js';
    import linkStoreModule from '../linkStoreModule';
    import useLinkList from '../link-list/useLinkList';

    import themeConfig from '@themeConfig';

    export default {
        data() {
            return {
                categories: ['Guias', 'Associações', 'Formulários', 'Eventos'],
                linkStoreModule,
                query: {},
            };
        },
        model: {
            prop: 'isFiltersActive',
            event: 'update:is-add-new-user-sidebar-active',
        },
        props: {
            isFiltersActive: {
                type: Boolean,
                required: true,
            },
        },

        setup() {
            const { linkListTable, fetchLinkList } = useLinkList();

            return {
                mdiClose,
                mdiCloseBoxOutline,
                linkListTable,
                fetchLinkList,
            };
        },
        created() {
            this.query = this.$store.getters['app-link/getLinkQuery'];
            this.fetchLinkList();
        },
        methods: {
            applyFilters() {
                this.query.pagination.page = 1;
                if (this.query.filters.category.$containsi === 'Guias')
                    this.query.filters.category.$containsi = 'Guides';
                else if (this.query.filters.category.$containsi === 'Associações')
                    this.query.filters.category.$containsi = 'Associations';
                else if (this.query.filters.category.$containsi === 'Formulários')
                    this.query.filters.category.$containsi = 'Forms';
                else if (this.query.filters.category.$containsi === 'Eventos')
                    this.query.filters.category.$containsi = 'Events';

                this.$store.commit('app-link/setLinkQuery', this.query);
                this.$emit('setQuery');
                this.$emit('update:is-add-new-user-sidebar-active', false);
            },
            deleteFilters() {
                this.query.filters.category.$containsi = '';
            },
        },
    };
</script>
